import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

const Index = (): JSX.Element => {
  useEffect(() => {
    navigate('/inicio')
  }, [])

  return <></>
}

export default Index
